import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState } from 'react';
const SiteContentSidebarContext = createContext({
    sidebarContainerRef: undefined,
    setSidebarContainerRef: () => { },
    isSidebarOpen: false,
    openSidebar: () => { },
    closeSidebar: () => { },
});
const useContextState = () => {
    const [sidebarContainerRef, setSidebarContainerRef] = useState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const openSidebar = () => {
        setIsSidebarOpen(true);
    };
    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };
    return {
        sidebarContainerRef,
        setSidebarContainerRef,
        isSidebarOpen,
        openSidebar,
        closeSidebar,
    };
};
const SiteContentSidebarProvider = ({ children = undefined, }) => {
    const state = useContextState();
    return (_jsx(SiteContentSidebarContext.Provider, { value: state, children: children }));
};
export { SiteContentSidebarContext };
export default SiteContentSidebarProvider;
