var ErrorCodes;
(function (ErrorCodes) {
    ErrorCodes["INVALID_KEYS"] = "INVALID_KEYS";
})(ErrorCodes || (ErrorCodes = {}));
// eslint-disable-next-line import/prefer-default-export
export const ConnectExchangeErrorCodes = {
    BINANCE: {
        INVALID_KEYS: 'binance.invalid_binance_account_api_keys',
    },
    BITMEX: {
        INVALID_KEYS: '',
    },
    FIDELITY: {
        INVALID_KEYS: '',
    },
};
