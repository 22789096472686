import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useSiteContentSidebar from '../../lib/useSiteContentSidebar';
// NOTE: SiteContentSidebar will be closed automatically once SiteContentSidebarPortal is unmounted
const SiteContentSidebarPortal = ({ children = undefined, }) => {
    const { sidebarContainerRef, isSidebarOpen, closeSidebar, } = useSiteContentSidebar();
    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        // TODO: figure out why closeSidebar in dependencies triggers following function to execute
        //  early, instead of the actual Unmount
        // this is analog to componentWillUnmount event handler
        return () => {
            // on unmount we want to closeSidebar automatically in case it wasn't closed
            closeSidebar();
        };
    }, []);
    if (sidebarContainerRef && isSidebarOpen) {
        return (createPortal(children, sidebarContainerRef));
    }
    return null;
};
export default SiteContentSidebarPortal;
