import { jsx as _jsx } from "react/jsx-runtime";
import Drawer from '@mui/material/Drawer';
import { useCallback } from 'react';
/**
 * raw sidebar element
 */
const Sidebar = ({ children = undefined, destroyOnHide = false, anchor = 'right', onClose = undefined, shouldCloseOnEsc = undefined, shouldCloseOnOutsideClick = undefined, isDisabled = undefined, isOpen, }) => {
    const handleClose = useCallback((_, reason) => {
        if (!isDisabled) {
            if (reason === 'escapeKeyDown' && shouldCloseOnEsc && onClose) {
                onClose();
            }
            else if (reason === 'backdropClick' && shouldCloseOnOutsideClick && onClose) {
                onClose();
            }
        }
    }, [onClose, shouldCloseOnEsc]);
    return (_jsx(Drawer, { "aria-hidden": !isOpen, open: isOpen, anchor: anchor, ModalProps: { keepMounted: !destroyOnHide }, onClose: handleClose, children: 
        // NOTE: render content only when the drawer is open
        isOpen && (children) }));
};
export default Sidebar;
