import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import CreateTraderProfile from '@widgets/sidebars/CreateTraderProfile';
import { useAppSelector } from '@store/Hooks';
import { GlobalSidebarElements } from '@store/slices/application';
import Sidebar from '@shared/ui/sidebars/Sidebar';
const GlobalSidebar = () => {
    const { isSidebarOpen, sidebarComponent } = useAppSelector(state => state.app);
    const globalElement = useMemo(() => {
        if (isSidebarOpen) {
            switch (sidebarComponent) {
                case GlobalSidebarElements.CreateTraderProfile:
                    return _jsx(CreateTraderProfile, {});
                default:
                    return null;
            }
        }
        return null;
    }, [isSidebarOpen, sidebarComponent]);
    return (_jsx(Sidebar, { isOpen: isSidebarOpen, destroyOnHide: false, children: globalElement }));
};
export default GlobalSidebar;
