import { useContext } from 'react';
import { SiteContentSidebarContext } from '../ui/SiteContentContextProvider';
const useSiteContentSidebar = () => {
    const context = useContext(SiteContentSidebarContext);
    if (!context) {
        throw new Error('internal error: you are trying to use "useSiteContentSidebar" outside of the provider zone. SiteContentSidebarContext not found.');
    }
    return context;
};
export default useSiteContentSidebar;
