import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn } from '@components/styled';
import useSiteContentSidebar from '../../lib/useSiteContentSidebar';
const SidebarWidth = 480;
const SidebarTransitionTime = 0.25;
const Container = styled(ContainerColumn)((props) => ({
    backgroundColor: `${props.theme.customColors.surface.surface}`,
    borderLeft: `1px solid ${props.theme.customColors.surface.outline}`,
    transition: `max-width ${SidebarTransitionTime}s`,
    transitionBehavior: 'allow-discrete',
}));
const ContentContainer = styled(ContainerColumn)(() => ({
    transition: 'opacity 0.05s',
    transitionDelay: `${SidebarTransitionTime}s`,
    transitionBehavior: 'allow-discrete',
}));
const SiteContentSidebar = ({ children = undefined, }) => {
    const { setSidebarContainerRef, isSidebarOpen } = useSiteContentSidebar();
    return (_jsx(Container, { style: {
            maxWidth: isSidebarOpen ? SidebarWidth : 0,
        }, children: _jsx(ContentContainer, { ref: setSidebarContainerRef, style: {
                opacity: isSidebarOpen ? 1 : 0,
            }, children: children }) }));
};
export default SiteContentSidebar;
