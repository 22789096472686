/* eslint-disable import/prefer-default-export */
export const EXCHANGE_NAMES = {
    BINANCE: {
        name: 'Binance',
        creationLink: 'https://accounts.binance.com/register',
        settingsPage: 'https://www.binance.com/my/settings/profile',
    },
    BITMEX: {
        name: 'Bitmex',
        creationLink: 'https://www.bitmex.com/app/register',
    },
    FIDELITY: {
        name: 'Fidelity',
        creationLink: 'https://fps.fidelity.com/ftgw/Fps/Fidelity/RtlAcctMgr/Resolve/InitNUR',
    },
};
export const EXTERNAL_LINKS = {
    BINANCE_SETTINGS: '',
    CREATE_BINANCE_ACCOUNT: '',
};
