import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { forwardRef, useImperativeHandle, useCallback, } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import ResetButton from '@shared/ui/buttons/ResetButton';
const InnerWrapperComponent = styled('div', { shouldForwardProp: prop => isPropValid(prop) })(props => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.l,
    padding: props.padding ? props.padding :
        `${props.theme.spacing_sizes.xs * 5}px 
                  ${props.theme.spacing_sizes.s * 5}px`,
    '@media (max-height: 960px)': {
        padding: props.padding ? props.padding :
            `${props.theme.spacing_sizes.xs * 5}px
             ${props.theme.spacing_sizes.s * 5}px`,
    },
    '@media (max-height: 800px)': {
        padding: props.padding ? props.padding : `${props.theme.spacing_sizes.s * 5}px`,
    },
}));
const TitleContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: props.hasTitle ? 'space-between' : 'end',
    alignItems: 'center',
}));
const ChildrenContainer = styled.div(() => ({
    display: 'flex',
    height: '100%',
}));
/**
 * component for sidebar children wrapping (with header and close btn)
 */
const SidebarInnerComponent = ({ className = undefined, children = undefined, cancelBtnProps = {}, displayCancelBtn = undefined, onClose = undefined, isDisabled = undefined, title = undefined, padding = undefined, }, ref) => {
    const handleCancelBtnClick = useCallback(() => {
        if (!isDisabled) {
            if (onClose && (cancelBtnProps === null || cancelBtnProps === void 0 ? void 0 : cancelBtnProps.closeSidebarOnClick)) {
                onClose();
            }
            else if (cancelBtnProps === null || cancelBtnProps === void 0 ? void 0 : cancelBtnProps.onClick) {
                cancelBtnProps.onClick();
            }
        }
    }, [cancelBtnProps, isDisabled, onClose]);
    useImperativeHandle(ref, () => ({
        handleClose: handleCancelBtnClick,
    }), [handleCancelBtnClick]);
    return (_jsxs(InnerWrapperComponent, { className: className, padding: padding, children: [Boolean(title) || Boolean(displayCancelBtn) ? (_jsxs(TitleContainer, { hasTitle: Boolean(title), children: [title || null, displayCancelBtn ? (_jsx(ResetButton, Object.assign({}, cancelBtnProps, { onClick: handleCancelBtnClick, disabled: isDisabled, iconWidth: 18, iconHeight: 18 }))) : null] })) : null, _jsx(ChildrenContainer, { hasTitle: Boolean(title), displayCancelBtn: displayCancelBtn, children: children })] }));
};
const SidebarInner = forwardRef(SidebarInnerComponent);
export default SidebarInner;
